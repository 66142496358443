@import 'abstracts/variables';

.ngb-pagination-pink {
  .page-link {
    color: $color-bright-pink;
  }

  .page-item.active .page-link {
    color: white;
    background-color: $color-bright-pink;
    border-color: $color-bright-pink;
  }
}

.ngb-pagination-black {
  .page-link {
    color: #222222;
  }

  .page-item.active .page-link {
    color: white;
    background-color: #222222;
    border-color: #222222;
  }
}
