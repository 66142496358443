.bg-pink {
  background-color: $color-bright-pink;
}

.bg-black {
  background-color: black;
}

.bg-light-gray {
  background-color: $color-white-smoke;
}
