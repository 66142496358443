@import 'abstracts/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.ngb-modal {
  &--retail {
    .modal-dialog {
      overflow: visible !important;
    }

    .modal-content {
      box-shadow: 0 5px 15px rgba($color-shark-dark, 0.2);
      border: none;
      border-radius: 0;
      min-width: 600px;
      background: $color-white !important;

      @include media-breakpoint-down(sm) {
        min-width: 0;
      }
    }
  }

  &--retail-small {
    .modal-dialog {
      overflow: visible !important;
    }

    .modal-content {
      box-shadow: 0 5px 15px rgba($color-shark-dark, 0.2);
      border: none;
      border-radius: 0;
      min-width: 300px;
      background: $color-white !important;
    }
  }

  &--md {
    .modal-dialog {
      height: 580px !important;
    }
  }

  &--lg {
    .modal-dialog {
      min-width: 600px !important;

      @include media-breakpoint-down(sm) {
        min-width: 0 !important;
      }
    }
  }

  &--xl {
    .modal-dialog {
      min-width: 710px !important;
    }
  }

  &--transparent-bg {
    .modal-content {
      background: transparent !important;
    }
  }
}

.modal-dialog {
  min-width: 350px;
  height: 410px;
  max-width: 500px !important;
}

.modal-dialog.modal-lg {
  height: 650px;
  overflow: hidden;
  min-width: 600px;
}

.modal-dialog.modal-xl {
  width: 800px;
  max-width: 100% !important;
  height: auto;
}

.modal--white-bg {
  & > .modal-dialog > .modal-content {
    background: $color-white !important;
  }
}
