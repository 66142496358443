@import 'abstracts/mixins';
@import 'abstracts/variables';

@mixin arrow($direction: 'right') {
  @include marginX(0 !important);
  content: if($direction == 'left', '‹', '›') !important;
  height: 30px;
  font-size: 25px;
  line-height: 28px;
}

.ngx-pagination {
  display: flex;
  align-items: center;
  height: 30px;

  li > a {
    color: $color-gray;
    height: 30px;
    overflow: hidden;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &:hover {
      text-decoration: none;
      background-color: $color-bright-pink;
      border-radius: 2px;
      color: $color-white;
    }

    &:focus {
      outline: none;
    }
  }

  li.current {
    background-color: $color-nero;
    border-radius: 2px;

    span {
      background-color: $color-nero;
    }
  }

  .pagination-previous {
    a::before,
    &.disabled::before {
      @include arrow('left');
    }
  }

  .pagination-next {
    a::after,
    &.disabled::after {
      @include arrow();
    }
  }
}
