$header-height: 80px;
$header-mobile-height: 75px;
$page-height: calc(100vh - #{$header-height});
$page-height-mobile: calc(100vh - #{$header-mobile-height});

$theme-retail: '.retail';

$color-white: #ffffff;
$color-black-white: #fffff8;
$color-seashell: #f1f1f1;
$color-seashell-2: #f2f1f1;
$color-alabaster: #f7f7f7;
$color-alabaster-2: #f8f8f8;
$color-black: #000000;
$color-white-smoke: #f8f8f8;
$color-silver: #b9b9b9;
$color-silver-2: #cacaca;
$color-silver-3: #c9c9c9;
$color-silver-light: #c2c2c2;
$color-silver-3: #c8c8c8;
$color-gray: #808080;
$color-lighter-gray: #929292;
$color-bright-pink: #fe7183;
$color-bittersweet: #ff5f5f;
$color-dawn-pink: #f4e9e9;
$color-gallery: #eee;
$color-gallery-2: #efefef;
$color-nobel: #b3b3b3;
$color-mantis: #6dc15e;
$color-mantis-2: #6ac259;
$color-brick-red: #bf2932;
$color-mine-shaft: #333333;
$color-grand-porcelain: #e5e5e5;
$color-silver-chalice: #b2b2b2;
$color-silver-chalice-2: #9d9d9d;
$color-silver-chalice-3: #a3a3a3;
$color-suva-gray: #888888;
$color-white-lilac: #dfdde1;
$color-unmatched-marble: #b8b8b8;
$color-alto: #d2d2d2;
$color-alto-2: #d8d8d8;
$color-alto-3: #d9d9d9;
$color-alto-4: #d4d4d4;
$color-very-light-gray: #cccccc;
$color-creased-silver: #999999;
$color-light-gray: #d3d3d3;
$color-old-line-ivory: #dcdcdc;
$color-nero: #1a1a1a;
$color-dark-gray: #a9a9a9;
$color-concrete: #f2f2f2;
$color-moveable-oyster: #e0e0e0;
$color-eclipse: #3e3d3d;
$color-dove-gray: #706e6e;
$color-dusty-gray: #9a9a9a;
$color-dusty-gray-2: #979797;
$color-scorpion: #5f5e5e;
$color-scorpion-2: #565656;
$color-mercury: #e9e9e9;
$color-tar: #191919;
$color-gainsboro: #e6e6e6;
$color-olivine: #8cba64;
$color-cinnabar: #e34242;
$color-tall-poppy: #c1272d;
$color-ghost: #ced4da;
$color-torch-red: #f40030;
$color-shark: #212529; // default text color
$color-shark-dark: #1f2224;
$color-off-white: #fdfdfd;
$color-wild-sand: #f4f4f4;
$color-maroon-flush: #c32661;
$color-brink-pink: #fd7083;
$color-granny-smith-apple: #aae995;
$color-tundora: #4a4a4a;
$color-tundora-2: #4d4d4d;
$color-moss-green: #a4d195;
$color-alizarin-crimson: #e0272c;
$color-mariner: #2e74c0;
$color-blumine: #1e538c;
$color-blue-zodiac: #142d54;
$color-magenta: #ff23f8;
$color-swamp: #000d0a;
$color-boulder: #747474;
$color-emerald: #58c169;
$color-cold-turkey: #d4c8cd;
$color-roman: #db5861;
$color-cod-gray: #0a0a0a;
$color-cod-gray-2: #0f0f0f;
$color-cod-gray-3: #151515;
$color-crimson: #d4145a;
$color-cannon-pink: #8e4060;
$color-burnt-sienna: #ed5656;
$color-botticelli: #dde4ef;
$color-ebb: #e6e0e0;
$color-sundown: #ffa6b2;
$color-rose-bud-cherry: #760830;
$color-venus: #959293;
$color-nevada: #6d7278;
$color-azure-radiance: #0091ff;
$color-soft-blue: #6eb3ff;
$color-bright-red: #d71616;
$color-nostalgic-evening: #485e71;
$color-dr-white: #f8fafb;
$color-pale-sky: #637280;
$color-sundown: #ffa7b1;
$color-lipstick: #bf0159;
