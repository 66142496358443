@import '../abstracts/variables';

.data-table {
  width: 100%;
  table-layout: fixed;
  font-size: 15px;

  thead {
    border: 1px solid $color-light-gray;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-style: solid;
    border-color: $color-light-gray;
    background-color: white;
    max-height: 60px;
    padding: 20px 10px;
    border-collapse: collapse;
    white-space: nowrap;
    text-overflow: ellipsis;

    th {
      height: 60px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  tbody {
    tr td {
      padding: 10px;
      border: 1px solid $color-light-gray;
      border-left: none;
      border-right: none;
    }

    tr td:first-child {
      border-left: 1px solid $color-light-gray;
    }

    tr td:last-child {
      border-right: 1px solid $color-light-gray;
    }

    tr:nth-child(odd) td {
      background-color: $color-white-smoke;
    }

    tr:nth-child(even) td {
      background-color: white;
    }
  }
}
