.pbg-theme {
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  font-weight: normal;
  color: #485e71;

  // ALERTS
  .alert {
    border-radius: 5px;
    border-width: 0;
    padding: 10px 18px;
  }

  .alert-info {
    color: #485e71;
    background-color: #d4e0ea;
  }

  // BUTTONS
  .btn {
    border-radius: 5px;
    border-width: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 600;
    letter-spacing: normal;
    line-height: normal;
    padding: 7px 28px;
    outline: none;
    text-align: center;

    &:focus,
    &:hover {
      box-shadow: none;
      outline: none;
    }
  }

  .btn-primary {
    background: linear-gradient(321deg, #bf0159 -40%, #ffa7b2 80%);
    box-shadow: 0 8px 20px -8px #fe7183;
    color: #ffffff;

    &:focus,
    &:hover {
      box-shadow: 0 8px 20px -3px #fe7183;
    }
  }

  .btn-link {
    color: #485e71;
    text-decoration: underline;

    &:focus,
    &:hover {
      text-decoration: none;
    }
  }

  // FORMS
  .form-control {
    height: 36px !important;
    outline: 0;
    border: 1px solid #7b8698;
    border-radius: 5px;
    color: #485e71;
    font-size: 13px;

    &:hover,
    &:focus {
      height: auto;
      outline: 0;
      color: #485e71;
      border: 1px solid #485e71;
      box-shadow: 0 0 9px -3px #7b8698 !important;
    }
  }

  .form-group {
    margin-bottom: 15px;
  }

  .form-group label {
    margin-bottom: 7px;
    font-size: 14px;
    font-weight: bold;
  }

  .form-control.is-invalid {
    border: 2px solid #fe7183 !important;
    background-image: none;
  }

  .invalid-feedback {
    color: #fe7183;
  }

  // NAV
  .nav-tabs {
    background: #ffffff;
    border: none;

    .nav-link {
      margin: 0;
      border: none;
      border-bottom: 5px solid #ffffff;
      color: #b9b9b9;
      padding: 10px;
      font-size: 16px;

      &.active {
        color: #000000;
        border-color: #000000;
      }
    }

    &-transparent {
      background: transparent;

      .nav-link {
        background: transparent;
        border-color: transparent;

        &.active {
          border-color: #000000;
        }
      }
    }
  }

  // MODAL
  .modal-content {
    background: #ffffff;
    border-radius: 10px;
  }

  .modal-header {
    padding: 20px 30px;
    border-bottom: 1px solid #dddddd;

    .modal-title {
      margin: 0;
      padding: 0;
      font-size: 20px;
      line-height: 1;
    }
  }

  .modal-body {
    padding: 20px 30px;
  }

  .modal-footer {
    padding: 20px 30px;
    border-top: 1px solid #dddddd;
  }

  // NG-SELECT
  .ng-select > .ng-select-container {
    color: #485e71;
  }

  .ng-select.ng-select-focused > .ng-select-container {
    border-color: #7b8698;
    box-shadow: 0 0 9px -3px #7b8698;
  }

  .ng-select.is-invalid > .ng-select-container {
    border: 2px solid #fe7183;
  }
}
