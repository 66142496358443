@import './mixins';
@import './variables';
@import './functions';

.u-center-element-vertically {
  @include center-element-vertically();
}

.u-align-right {
  text-align: right;
}

.u-align-center {
  text-align: center;
}

.u-capitalize {
  text-transform: capitalize;
}

.u-lowercase {
  text-transform: lowercase !important;
}

.u-pad-top-five {
  margin-top: 5px;
}

.u-no-scroll {
  overflow: hidden !important;
}

$fontWeights: 100 200 300 400 500 600 700 normal;
@each $weight in $fontWeights {
  .u-font-weight-#{$weight} {
    font-weight: $weight !important;
  }
}

.u-prepend-circle {
  &::before {
    content: '';
    display: inline-block;
    align-self: center;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: $color-shark;
    margin-right: 5px;
  }
}

.u-prepend-green-circle {
  @extend .u-prepend-circle;

  &::before {
    background-color: $color-granny-smith-apple;
  }
}

.u-prepend-grey-circle {
  @extend .u-prepend-circle;

  &::before {
    background-color: $color-silver-light;
  }
}

$width: 100;
@while $width < 401 {
  .u-min-width-#{$width} {
    min-width: convertToPx($width);
  }
  $width: $width + 10;
}

.u-hide-overflow {
  overflow: hidden;
}

.u-underline {
  text-decoration: underline;
}

.u-pink {
  color: $color-brink-pink !important;
}

.u-bg-white {
  background: $color-white !important;
}

.u-position-absolute {
  position: absolute !important;
}

.u-wh-100 {
  @include dimensions(100% !important, 100% !important);
}
