@import '../abstracts/variables';
@import '../abstracts/mixins';

app-page-content,
app-pharmacy-main,
experience {
  .ng-select-container {
    height: 50px !important;
    transition: all 0.3s ease-in-out;
    border: 1px solid $color-very-light-gray !important;
    overflow: visible !important;

    &:focus,
    &:hover,
    &:active {
      box-shadow: inset 0 0 0 2px $color-light-gray !important;
    }

    .ng-value-container {
      .ng-value {
        background-color: transparent !important;

        .ng-value-icon {
          display: none !important;
        }

        .ng-value-label {
          color: $color-shark;
          font-size: 0.9375rem;
        }
      }

      .ng-placeholder {
        padding-bottom: 0 !important;
        padding-top: 3px;
        font-size: 0.9375rem;
        color: $color-shark !important;
        top: unset !important;
      }

      // When more than 1 item is selected, display the # selected, instead
      // of each item selected.
      // Display amount selected for up to 11 - 1 = 10 items
      @for $i from 3 through 11 {
        .ng-value:nth-last-child(#{$i}) {
          .ng-value-label {
            color: transparent;
            width: 0;

            &::before {
              content: '#{$i - 1} Selected' !important;
              color: $color-shark;
            }
          }
        }
      }

      & > *:nth-child(n + 3) {
        display: none;
      }
    }
  }

  .ng-dropdown-panel.ng-select-multiple {
    .ng-option {
      display: flex !important;
      align-items: center;
      font-weight: 600;
      height: 27px;
    }

    .ng-option-selected {
      &::after {
        content: '\2713'; // check mark
        font-size: 18px;
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }

  .ng-select.ng-select--self-serve {
    margin-right: 15px;

    &.ng-select-focused {
      .ng-select-container {
        &:focus,
        &:hover,
        &:active {
          box-shadow: inset 0 0 0 2px $color-light-gray !important;
          border-width: 0;
        }
      }
    }

    .ng-select-container {
      @include font(16px, 500 !important);
      border: 1px solid $color-very-light-gray !important;
      height: 40px !important;
      transition: all 0.3s ease-in-out;

      &:focus,
      &:hover,
      &:active {
        box-shadow: inset 0 0 0 2px $color-light-gray !important;
        border-width: 0;
      }

      .ng-value-label {
        @include font(16px, 500);
      }

      .ng-input {
        width: 0;
      }
    }
  }

  ng-select.width-auto {
    .ng-dropdown-panel {
      width: auto !important;
    }
  }

  ng-select.dropdown-right {
    .ng-dropdown-panel {
      left: auto !important;
      right: 0;
    }
  }

  .ng-select--dark {
    &.ng-select {
      .ng-select-container {
        box-shadow: 10px 10px 80px 0 rgba(0, 0, 0, 0.5) !important;
        border-style: solid !important;
        border-width: 1px !important;
        border-image-source: linear-gradient(107deg, $dark--color-mine-shaft 12%, #4f4f4f 21%, #28292f 80%) !important;
        border-image-slice: 1 !important;
        border-radius: 2px;
        background: unset;
      }
    }

    .ng-option {
      background: linear-gradient(to bottom, #111315, #0f1012 44%);
      color: $dark--color-slate-gray !important;
      font-size: 0.6875rem;
      text-transform: capitalize;

      &:hover {
        color: $color-white !important;
      }
    }

    .ng-dropdown-panel {
      border-style: solid !important;
      border-width: 1px !important;
      border-image-source: linear-gradient(107deg, $dark--color-mine-shaft 12%, #4f4f4f 21%, #28292f 80%) !important;
      border-image-slice: 1 !important;
      border-radius: 8px;
    }

    .ng-value-label {
      color: #88909e !important;
      text-transform: capitalize;
      font-weight: 400;
      font-size: 0.6875rem !important;
    }

    .ng-placeholder {
      text-transform: capitalize;
    }
  }

  // Transparent background theme
  .ng-select-transparent-background {
    &.ng-select {
      .ng-select-container {
        height: 30px !important;
        min-height: unset;
        background: unset;
        border: unset !important;
      }

      .ng-value-container {
        justify-content: flex-end;

        .ng-value .ng-value-label {
          color: $color-gray;
        }
      }

      .ng-select-container .ng-arrow-wrapper {
        padding: 0;

        .ng-arrow {
          height: 20px;
          border: unset;
          left: -5px;

          &::before,
          &::after {
            position: absolute;
            display: block;
            content: '';
            border: 10px solid transparent;
          }

          &::before {
            border-width: 5px;
            top: calc(50% + 2px);
            border-top-color: $color-creased-silver;
            bottom: unset;
            border-bottom-color: transparent;
          }

          &::after {
            border-width: 5px;
            top: 50%;
            border-top-color: $color-white-smoke;
            bottom: unset;
            border-bottom-color: transparent;
          }
        }
      }

      .ng-dropdown-panel .ng-dropdown-panel-items {
        .ng-option {
          @include paddingY(2px);

          .ng-option-label {
            font-size: 15px;
          }
        }
      }
    }

    &.ng-select-opened {
      .ng-select-container {
        background: $color-white;
        border: 1px solid $color-very-light-gray !important;
        border-color: $color-nobel $color-very-light-gray $color-alto !important;

        .ng-arrow-wrapper .ng-arrow {
          &::before {
            border-width: 5px;
            top: calc(50% - 2px);
            border-bottom-color: $color-creased-silver;
            border-top-color: transparent;
          }

          &::after {
            border-width: 5px;
            top: 50%;
            border-bottom-color: $color-white;
            border-top-color: transparent;
          }
        }
      }
    }
  }

  // box-shadow theme
  .ng-select--shadow {
    .ng-select-container {
      box-shadow: 0 10px 20px 0 rgba($color-creased-silver, 0.4) !important;

      .ng-dropdown-panel {
        box-shadow: 0 10px 20px 0 rgba($color-creased-silver, 0.4);
      }
    }
  }

  // border-radius 5px theme
  .ng-select--border-radius-5 {
    .ng-select-container {
      border-radius: 5px;

      .ng-dropdown-panel {
        .ng-dropdown-panel-items {
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
  }

  // no border theme
  .ng-select--no-border {
    .ng-select-container {
      border: none !important;

      .ng-dropdown-panel {
        border: none;
      }
    }
  }

  // height 40px theme
  .ng-select--height-40 {
    .ng-select-container {
      height: 40px !important;
    }
  }

  // height 22px theme
  .ng-select--height-22 {
    .ng-select-container {
      height: 22px !important;
      min-height: 0;
    }

    .ng-value-label {
      height: 22px;
      line-height: 22px;
    }

    .ng-arrow-wrapper {
      height: 22px;

      .ng-arrow {
        height: 22px !important;
        top: 0 !important;
      }
    }
  }

  // height 25px theme
  .ng-select--height-25 {
    .ng-select-container {
      height: 25px !important;
      min-height: 0;
    }

    .ng-value-label {
      height: 25px;
      line-height: 25px;
    }

    .ng-arrow-wrapper {
      height: 25px;

      .ng-arrow {
        height: 25px !important;
        top: 0 !important;

        &::after {
          top: 4px !important;
        }
      }
    }

    &.ng-select-opened {
      .ng-arrow::after {
        top: -3px !important;
      }
    }
  }

  // padding for paginator
  .ng-select--padding-top-10 {
    padding-top: 10px !important;
  }

  // narrow arrow theme
  .ng-select--narrow-arrow {
    .ng-select-container {
      .ng-arrow-wrapper {
        .ng-arrow {
          border: none;
          position: absolute;
          right: 0;
          width: 30px;
          height: 40px;
          top: -20px;
          display: flex;
          align-items: center;
          justify-content: center;
          bottom: 0;

          &::after {
            content: '〉';
            transform: translateZ(0) rotate(90deg);
            font-size: 0.8rem;
            position: relative;
            right: 28%;
            top: 3px;
            color: $color-tundora-2;
            transition: all 150ms ease-in-out;
          }
        }
      }
    }

    &.ng-select-opened {
      .ng-select-container {
        .ng-arrow-wrapper {
          .ng-arrow::after {
            transform: rotate(-90deg);
            top: -3px;
          }
        }
      }
    }
  }

  // padding-top theme
  .ng-select--padding-top {
    .ng-placeholder {
      padding-bottom: 0 !important;
      padding-top: 3px;
      font-size: 15px;
      color: $color-shark !important;
    }
  }

  // no-row-height theme
  .ng-select--no-row-height {
    .ng-dropdown-panel {
      .ng-dropdown-panel-items {
        .ng-option {
          height: unset;

          .ng-option-label {
            white-space: normal;
          }
        }
      }
    }
  }

  // font-weight-normal theme {
  .ng-select--fw-normal {
    .ng-dropdown-panel {
      .ng-dropdown-panel-items {
        .ng-option {
          .ng-option-label {
            font-weight: normal !important;
          }
        }
      }
    }
  }

  // font-size-small theme {
  .ng-select--fs-small {
    .ng-dropdown-panel {
      .ng-dropdown-panel-items {
        .ng-option {
          .ng-option-label {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: #cccccc;
}

.ng-select.ng-select-focused > .ng-select-container {
  border-color: #cccccc;
}

.ng-select.is-invalid > .ng-select-container {
  border: 2px solid #fe7183;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  height: auto !important;
}

.ng-select.ng-select--auto {
  .ng-dropdown-panel {
    width: auto !important;
    min-width: 100%;
  }
}

.ng-select.ng-select--menu-right {
  .ng-dropdown-panel {
    left: auto;
    right: 0;
  }
}
