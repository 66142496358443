.text--primary {
  color: $dark--color-primary;
}

.text--success {
  color: $dark--color-success;
}

.text--warning {
  color: $dark--color-warning;
}

.text--danger {
  color: $dark--color-danger;
}

.text--gray {
  color: $dark--color-font;
}
