@import 'variables';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';

@mixin center-element($doTranslateZ: false) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: if($doTranslateZ, translate3d(-50%, -50%, 0), translate(-50%, -50%));
}

@mixin center-element-vertically() {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin center-element-horizontally() {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

@mixin no-glow() {
  outline: none !important;
  box-shadow: none !important;
  outline-color: transparent;
  outline-style: none;
  border: none !important;
}

@mixin paddingX($padding) {
  padding-left: $padding;
  padding-right: $padding;
}

@mixin paddingY($padding) {
  padding-top: $padding;
  padding-bottom: $padding;
}

@mixin marginX($margin) {
  margin-left: $margin;
  margin-right: $margin;
}

@mixin marginY($margin) {
  margin-top: $margin;
  margin-bottom: $margin;
}

@mixin flexRow() {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@mixin flexRowAlignCenter() {
  @include flexRow();
  align-items: center;
}

@mixin flexRowCenter() {
  @include flexRowAlignCenter();
  justify-content: center;
}

@mixin flexCol() {
  display: flex;
  flex-direction: column;
}

@mixin flexColAlignCenter() {
  @include flexCol();
  align-items: center;
}

@mixin flexColCenter() {
  @include flexColAlignCenter();
  justify-content: center;
}

@mixin dimensions($width, $height) {
  width: $width;
  height: $height;
}

@mixin absoluteTopRight() {
  position: absolute;
  top: 0;
  right: 0;
}

@mixin absoluteTopLeft() {
  @include absoluteTopRight();
  left: 0;
}

@mixin absoluteTopCenter() {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin absoluteBottomLeft() {
  position: absolute;
  bottom: 0;
  left: 0;
}

@mixin border($sides: all, $radius: 3px, $width: 1px, $color: $color-ghost, $styleSet: solid) {
  // Possible sides: all, x, y, left, right, top, bottom
  @if $sides == all {
    border: $width $styleSet $color;
  } @else if $sides == x {
    border-left: $width $styleSet $color;
    border-right: $width $styleSet $color;
  } @else if $sides == y {
    border-top: $width $styleSet $color;
    border-bottom: $width $styleSet $color;
  } @else if $sides == left {
    border-left: $width $styleSet $color;
  } @else if $sides == right {
    border-right: $width $styleSet $color;
  } @else if $sides == top {
    border-top: $width $styleSet $color;
  } @else if $sides == bottom {
    border-bottom: $width $styleSet $color;
  }

  border-radius: $radius;
}

@mixin borderRadius($topLeft, $topRight, $bottomRight, $bottomLeft) {
  border-top-left-radius: $topLeft;
  border-top-right-radius: $topRight;
  border-bottom-right-radius: $bottomRight;
  border-bottom-left-radius: $bottomLeft;
}

@mixin font($size: inherit, $weight: normal, $transform: inherit, $letterSpacing: inherit, $color: $color-shark, $opacity: 1) {
  font-size: $size;
  font-weight: $weight;
  text-transform: $transform;
  letter-spacing: $letterSpacing;
  color: $color;
  opacity: $opacity;
}

@mixin textEllipsis() {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

// Breakpoint mixins:

// Example:
// @include media-down((
//   $breakpoint-xl: (
//     font-size: 2rem,
//     color: red
//   ),
//   $breakpoint-lg: (font-size: .7rem),
// ));
@mixin media-down($breakpointStylesMap) {
  @each $breakpoint, $stylesMap in $breakpointStylesMap {
    @media (max-width: $breakpoint) {
      @each $property, $value in $stylesMap {
        #{$property}: $value;
      }
    }
  }
}

@mixin media-up($breakpointStylesMap) {
  @each $breakpoint, $stylesMap in $breakpointStylesMap {
    @media (min-width: $breakpoint) {
      @each $property, $value in $stylesMap {
        #{$property}: $value;
      }
    }
  }
}

@mixin quantity-query($number, $comparison: 'equal') {
  $nth: null;
  @if index(('greater' 'more' '>'), $comparison) {
    $nth: 'n + #{$number}';
  } @else if index(('less' 'fewer' '<'), $comparison) {
    $nth: '-n + #{$number}';
  } @else if index(('equal' 'same' '='), $comparison) {
    $nth: $number;
  } @else {
    @warn "Sorry, that’s an invalid $comparison value.";
  }

  &:nth-last-child(#{$nth}):first-child {
    &,
    & ~ * {
      @content;
    }
  }
}

@mixin flareBorderY() {
  border-style: solid;
  border-width: 1px;
  border-image-source: linear-gradient(
    to right,
    rgba(86, 93, 103, 0.1),
    #8898a9 41%,
    rgba(255, 255, 255, 0.94) 49%,
    rgba(130, 144, 160, 0.88) 59%,
    rgba(83, 86, 101, 0.01)
  );
  border-image-slice: 1;
  border-left: 0;
  border-right: 0;
}
