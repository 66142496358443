@import 'abstracts/variables';
@import 'abstracts/mixins';

.nav {
  &--tabs {
    background: transparent;
    position: relative;
    z-index: 1;

    .nav-item {
      background: $color-white;
      position: relative;

      &--active {
        box-shadow: 0 10px 20px 0 rgba($color-creased-silver, 0.4);
      }

      &::after {
        content: '';
        @include dimensions(100%, 10px);
        background: $color-white;
        position: absolute;
        bottom: -10px;
      }
    }

    .nav-link {
      background-color: rgba($color-alto-2, 0.23);
      @include font(0.75rem, 600, $color: $color-silver-chalice-3);
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      &:hover:not(.active) {
        background-color: $color-gallery;
      }

      &.active {
        background-color: unset;
        @include font(0.75rem, 600, $color: $color-shark);
      }
    }
  }
}

.tab-pane {
  @include dimensions(100%, 100%);
}
