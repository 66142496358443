@import '../abstracts/mixins';
@import '../abstracts/variables';

.button {
  @include no-glow();
  padding: 1px 20px;
  letter-spacing: 1px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-style: solid;
  border-radius: 3px;
  text-transform: uppercase;
  min-width: 120px;
  height: 50px !important;
  font-size: 14px;
  margin-top: 0px !important;
  width: auto;
  margin-bottom: 1px;
  cursor: pointer;

  &:disabled,
  &--disabled {
    background: $color-silver-chalice !important;
    color: $color-suva-gray !important;
    pointer-events: none;
    box-shadow: none !important;
  }

  &--default {
    background-color: black;
    border-color: black;
    color: white;

    &:hover,
    &:focus,
    &:active {
      background-color: $color-bright-pink;
      border-color: $color-bright-pink;
    }
  }

  &--primary {
    background-color: $color-bright-pink;
    border-color: $color-bright-pink;
    color: white;

    &:hover,
    &:focus,
    &:active {
      background-color: black;
      border-color: black;
    }
  }

  &--gradient {
    background: linear-gradient(-90deg, #fd7083 0%, #c32661 100%);
    color: white;
  }

  &--clear {
    background: transparent;
    border: 1px solid $color-suva-gray !important;
    height: 30px !important;
    text-transform: none;
  }

  &--small {
    height: 35px !important;
  }
}

// For component themes to extend these, they cannot be children of another class,
// e.g., .button. They must be top-level rules to be extended.
.button--retail {
  display: inline-flex;
  align-items: center;
  position: relative;
  color: $color-white;
  border-radius: 30px;
  background: linear-gradient(90deg, $color-maroon-flush, $color-brink-pink);
  font-size: 18px;
  font-weight: 400;
  text-transform: unset;
  letter-spacing: unset;
  box-shadow: 0 3px 20px rgba(195, 38, 97, 0.2) !important;

  &:disabled,
  &.button--disabled {
    color: $color-white !important;
    background: linear-gradient(to right, $color-cold-turkey, $color-lighter-gray) !important;
    box-shadow: none !important;
  }

  &::after {
    content: url('/assets/icons/arrow_forward_white.svg');
    position: absolute;
    top: 50%;
    right: 5px;
    transform: scale(0.8) translateY(-50%);
  }

  &--tokenized-login {
    border-radius: 5px;
    box-shadow: 0px 10px 17px -4px $color-sundown !important;

    background-size: 100%;
    background-image: linear-gradient(130deg, $color-sundown 30%, $color-lipstick 120%);
    position: relative;
    z-index: 100;

    &:before {
      border-radius: 5px;
      background-image: linear-gradient(130deg, $color-lipstick 30%, $color-sundown 120%);
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      z-index: -100;
      transition: opacity 0.5s ease-out;
    }

    &:focus,
    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.button--retail-small {
  @extend .button--retail;
  font-size: 15px;
  max-height: 35px;
  line-height: 35px;
}

.button--retail-medium {
  @extend .button--retail;
  font-size: 15px;
  max-height: 40px;
}

.button--retail-centered {
  @extend .button--retail;
  justify-content: center;
  box-shadow: 0 3px 20px 0 rgba(142, 64, 96, 0.15);
  background-image: linear-gradient(107deg, #d4145a, #fd7083 100%, #d4145a 100%);

  &::after {
    content: none;
  }
}

.button--retail-centered-dark {
  @extend .button--retail-centered;
  background: linear-gradient(117deg, $color-black 1%, $color-venus 102%);
}

.button--retail-clear {
  @extend .button--retail-centered;
  background: unset;
  color: $color-shark;
  border: 1px solid $color-gainsboro !important;
}

.button--retail-default {
  @extend .button--retail-centered;
  background-image: linear-gradient(to right, $color-black, $color-venus);
}

.button--care {
  height: 41px !important;
  min-width: 150px;
  color: $color-white;
  border-radius: 5px;
  background-image: linear-gradient(319deg, #bf0159 -37%, #ffa7b2 100%);
  box-shadow: 0 6px 20px -6px #bf0159 !important;
  font-size: 20px;
  font-weight: 600;
  text-transform: unset;
  letter-spacing: unset;
}

.button--care--outline {
  height: 41px !important;
  padding: 1px 15px;
  color: #485e71;
  border-radius: 8px;
  box-shadow: 0 4px 12px 0 rgba(72, 94, 113, 0.04) !important;
  border: solid 1px #c3cbd7 !important;
  background-color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-transform: unset;
  letter-spacing: unset;
}
