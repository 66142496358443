@import 'abstracts/variables';
@import 'abstracts/mixins';

.ngb-dp-today > div {
  @extend .btn-outline-light;
  color: #111 !important;
}

.form-select {
  line-height: 26px;
  padding: 0 0 0 6px;
}

.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
}

.custom-day.focused {
  background-color: #e6e6e6;
}

.custom-day.range,
.custom-day:hover {
  background-color: $color-bright-pink;
  color: white;
}

.custom-day.faded {
  background-color: rgba($color-bright-pink, 0.5);
}

.custom-day.disabled {
  background-color: #ffffff;
  color: #eeeeee;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: $color-bright-pink !important;
  background-color: $color-bright-pink !important;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba($color-bright-pink, 0.25) !important;
}

.ngb-datepicker--gray ~ ngb-datepicker {
  .ngb-dp-navigation-chevron {
    color: $color-silver-3;
  }

  .ngb-dp-arrow-btn {
    &:focus {
      outline: none;
    }
  }

  .bg-primary {
    background: $color-silver-3 !important;
  }
}

.ngb-datepicker--narrow-arrows ~ ngb-datepicker {
  .ngb-dp-navigation-chevron {
    border-top-width: 0.1em;
    border-right-width: 0.1em;
    @include dimensions(0.5rem, 0.5rem);
  }
}

.ngb-datepicker-dark {
  ngb-datepicker.dropdown-menu {
    width: 260px;
    padding: 20px 20px 8px;
    margin: 0;
    color: #666e7b;
    background: linear-gradient(329deg, #191a1b 11%, #0b0b0c 100%);
    box-shadow:
      0 2px 50px 0 #000000,
      0 2px 50px 0 #000000,
      0 10px 25px 0 #000000;
    border-image-source: radial-gradient(circle at 50% 100%, #ffffff, rgba(0, 0, 0, 0) 40%);
    border-image-slice: 1;
  }

  .ngb-dp-header {
    padding: 0;
    border: none;
    border-radius: 0;
    background: unset;
  }

  .ngb-dp-week {
    padding: 0;
  }

  .ngb-dp-months {
    margin-top: 13px;
  }

  .ngb-dp-month {
    width: 100%;
  }

  .ngb-dp-month-name {
    background: unset;
    color: #717987;
    font-size: 11px;
    text-transform: uppercase;
    height: auto;
    line-height: 15px;
  }

  .ngb-dp-arrow {
    height: 15px;
  }

  .ngb-dp-arrow-btn {
    color: inherit !important;
    border: none;
    outline: none !important;
    box-shadow: none !important;
    margin: 0;
    padding: 0 10px;
    line-height: 0;
  }

  .ngb-dp-navigation-chevron {
    width: 6px;
    height: 6px;
    margin: 0;
    border-width: 1px 1px 0 0;
    color: #666e7b;
  }

  .ngb-dp-weekdays {
    border-radius: 0;
    border-top: 1px solid #25282d;
    border-bottom: 1px solid #25282d !important;
    margin-bottom: 8px;
    background: unset !important;
  }

  .ngb-dp-weekday {
    @include font(0.5625rem, $color: white !important);
    @include flexRowCenter();
    height: 24px;
    line-height: 24px;
    font-style: unset !important;
  }

  .ngb-dp-day {
    overflow: hidden;
    height: 30px;
    width: 30px;
  }

  .ngb-dp-day > div {
    @include font(0.5625rem, $color: #666e7b);
    height: 30px;
    width: 30px;
    border-radius: 100%;
    background-color: transparent;
  }

  .ngb-dp-day > div:focus,
  .ngb-dp-day > div:active,
  .ngb-dp-day > div.active {
    color: inherit !important;
    background-color: inherit !important;
  }

  .ngb-dp-day > .bg-primary {
    color: white !important;
    text-shadow: 0 0 5px #ffc0c8;
    background-color: transparent !important;
    background-image: url('/assets/icons/small-circle-active.svg');
    background-size: 30px 30px;
    background-repeat: no-repeat;
  }

  .ngb-dp-day:not(.disabled):hover > div {
    background-color: #1a1b1c !important;
  }

  .ngb-dp-day.disabled > div {
    color: #32363f !important;
  }

  .ngb-dp-day > div.active.bg-primary {
    color: white !important;
  }
}
