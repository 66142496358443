.modal-dialog {
  .modal-title {
    font-size: 1.5rem;
    font-weight: 400;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .close[type='button'] {
    &:focus {
      outline: none;
    }
  }
}

.need-help-modal {
  &__img {
    height: 54px;
    width: 54px;
    margin-right: 15px;
  }

  &__title {
    font-size: 1.188rem;
    margin: 0;
  }
}
