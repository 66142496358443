/* You can add global styles to this file, and also import other style files */
/*sass/
|
|– abstracts/
|   |– _variables.scss    # Sass Variables
|   |– _functions.scss    # Sass Functions
|   |– _mixins.scss       # Sass Mixins
|   |– _placeholders.scss # Sass Placeholders
|
|– base/
|   |– _reset.scss        # Reset/normalize
|   |– _typography.scss   # Typography rules
|   …                     # Etc.
|
|– components/
|   |– _buttons.scss      # Buttons
|   |– _carousel.scss     # Carousel
|   |– _cover.scss        # Cover
|   |– _dropdown.scss     # Dropdown
|   …                     # Etc.
|
|– layout/
|   |– _navigation.scss   # Navigation
|   |– _grid.scss         # Grid system
|   |– _header.scss       # Header
|   |– _footer.scss       # Footer
|   |– _sidebar.scss      # Sidebar
|   |– _forms.scss        # Forms
|   …                     # Etc.
|
|– pages/
|   |– _home.scss         # Home specific styles
|   |– _contact.scss      # Contact specific styles
|   …                     # Etc.
|
|– themes/
|   |– _theme.scss        # Default theme
|   |– _admin.scss        # Admin theme
|   …                     # Etc.
|
|– vendors/
|   |– _bootstrap.scss    # Bootstrap
|   …                     # Etc.
|
`– styles.scss              # Main Sass file*/

$font-family-base: 'Poppins', sans-serif;
$font-family-sans-serif: 'Poppins', sans-serif;

@import 'abstracts/variables';
@import 'abstracts/variables-dark';
@import 'abstracts/mixins';
@import 'abstracts/utils';
@import 'abstracts/breakpoints';
@import 'base/backgrounds';
@import 'base/sizing';
@import 'base/global';
@import 'base/text';
@import 'components/table';
@import 'components/buttons';
@import 'components/modals';
@import 'components/nav';
@import 'vendor/ng-select';
@import './vendor/ngb-modal.scss';
@import './vendor/ngb-progressbar.scss';
@import 'bootstrap/scss/bootstrap';
@import './vendor/ngb-datepicker';
@import './vendor/ngb-pagination';
@import './vendor/ngb-timepicker';
@import './vendor/ngx-pagination.scss';
@import './vendor/jointjs';
@import 'chitin/variables/colors.default';
@import 'chitin/variables/colors.dark';

$agent-call-bar-height: 80px;
$agent-btn-width: 120px;

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(50, 50, 50, 0.2);
  border-right: 1.1em solid rgba(50, 50, 50, 0.2);
  border-bottom: 1.1em solid rgba(50, 50, 50, 0.2);
  border-left: 1.1em solid $color-white;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.modal {
  overflow-y: auto;
  -ms-overflow-style: auto;
}

.active .panel-heading {
  background-color: $color-old-line-ivory;
}

h5 {
  font-weight: bold;
}

.form-inline {
  display: flex;

  .form-group {
    display: flex;
  }
}

.form-control {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border-color: $color-very-light-gray;
  color: $color-creased-silver;
  font-weight: 400 !important;
  height: 36px;
  font-size: 15px;
}

.form-control:focus,
.form-control:hover,
.form-control:active {
  box-shadow: none !important;
  -webkit-box-shadow: none;
  border-width: 3px;
  border-color: $color-bright-pink;
  color: $color-creased-silver;
  font-weight: 400 !important;
  height: 36px;
  font-size: 15px;
}

input[type='text'],
textarea,
tel {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

input[type='text']:focus,
input[type='text']:hover,
input[type='text']:active,
input[type='password']:focus,
input[type='password']:hover,
input[type='password']:active,
input[type='tel']:focus,
input[type='tel']:hover,
input[type='tel']:active,
textarea:focus,
textarea:hover,
textarea:active {
  box-shadow: none !important;
  -webkit-box-shadow: none;
  border-width: 2px;
  border-color: $color-bright-pink;
  outline: 0;
}

#exampleCheck1 {
  padding-left: 10px;
}

html {
  min-height: 100%;
  background-color: $color-white-smoke;
}

body {
  background-color: $color-nero;
  min-height: 100vh;
}

body.modal-open {
  padding-right: 0px !important;
}

.pointer {
  cursor: pointer;
}

.aTextLink {
  color: $color-bright-pink;
  text-decoration: underline;
}

.body_img {
  background-color: $color-white-smoke;
  min-height: 100%;

  @include media-breakpoint-down(lg) {
    min-height: 100vh;
  }
}

.body_img_alt {
  background-color: $color-white-smoke;
}

.sidebar-blk-bg {
  background-color: black;
  width: 100%;
  min-height: 100vh;
  min-height: 100%;
}

.standardBackground {
  background-color: $color-white-smoke;
}

.pnlSize {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 525px;
  background: $color-white;
}

.modal-header h3 {
  text-align: center;
  justify-content: center;
  width: 100%;
  font-weight: 100;
  padding: 15px 0;
  font-size: 18px;
  font-style: normal;
}

.modal-dialog {
  min-width: 300px;
}

.modal .modal-header,
.modal .modal-body {
  border-bottom: none;
}

.modal .modal-footer {
  border-top: none;
  text-align: center;
  justify-content: center;
}

.modal .modal-footer .button--primary {
  display: inline-block;
}

.elementCenter {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.lineStyle {
  color: $color-dark-gray;
  width: 300px;
  margin: 0 auto;
  justify-content: center;
}

.fillPage {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  height: 100%;
}

.txtBlack {
  color: $color-black;
}

.txtWhite {
  color: $color-white;
}

.btn {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-style: solid;
  border-radius: 3px;
}

.btnLogin {
  width: 100%;
  height: 40px;
  margin-bottom: 30px;
  border-radius: 3px;
}

.login {
  background-color: $color-bright-pink;
  border-color: $color-bright-pink;
  padding: 5px 20px;
  color: white;
  letter-spacing: 1px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-style: solid;
  border-radius: 3px;
  text-transform: uppercase;
  min-width: 97px;
  height: 50px;
  font-size: 14px;
  cursor: pointer;
}

.login:hover,
.login:focus,
.login:active {
  background-color: $color-black;
  border-color: $color-black;
  color: white;
  padding: 5px 20px;
  letter-spacing: 1px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-style: solid;
  border-radius: 3px;
  text-transform: uppercase;
  min-width: 97px;
  font-size: 14px;
  height: 50px;
  outline: none;
}

.login:disabled {
  background-color: $color-silver-chalice;
  border-color: $color-silver-chalice;
  color: #888888;
}

.decline {
  background-color: black;
  border-color: black;
  padding: 5px 20px;
  color: white;
  letter-spacing: 1px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-style: solid;
  border-radius: 3px;
  text-transform: uppercase;
  min-width: 97px;
  height: 50px;
  font-size: 14px;
}

.decline:hover,
.decline:focus,
.decline:active {
  background-color: $color-bright-pink;
  border-color: $color-bright-pink;
  color: white;
  padding: 5px 20px;
  letter-spacing: 1px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-style: solid;
  border-radius: 3px;
  text-transform: uppercase;
  min-width: 97px;
  font-size: 14px;
  height: 50px;
  outline: none;
}

.btn-xs {
  font-size: 10px;
  padding: 2px 8px;
}

.btn-sm-1 {
  background-color: $color-bright-pink;
  border-color: $color-bright-pink;
  padding: 3px 17px;
  color: white;
  letter-spacing: 1px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-style: solid;
  max-height: 34px;
  min-height: 28px;
  font-size: 9px;
  width: auto;
  border-radius: 3px;
  text-transform: uppercase;
  margin-top: 4px;
}

.btn-sm-1.black {
  background: black;
  border-color: black;
}

.btn-sm-1:hover,
.btn-sm-1:focus,
.btn-sm-1:active {
  background-color: black;
  border-color: black;
}

.btn-sm-1:disabled {
  border-color: $color-silver-chalice !important;
  background: $color-silver-chalice !important;
  color: $color-suva-gray !important;
}

.btnDiv {
  border-bottom: 1px;
  border-bottom-style: solid;
  color: $color-dark-gray;
  margin-bottom: 10px;
  padding-bottom: 20px;
}

.forgotPass {
  font-size: 10px;
  color: $color-bright-pink;
  padding-bottom: 20px;
  text-decoration: underline;
}

.register {
  margin-top: 30px;
}

.btnRegister {
  background-color: #c8c8c8;
  border-color: $color-bright-pink;
  padding: 10px 20px;
  width: 120px;
}

.btnRegister:hover {
  background-color: $color-bright-pink;
  color: $color-white;
}

.firstUser {
  padding-top: 10px;
}

.remember {
  padding-bottom: 10px;
}

.btnReset {
  padding-top: 10px;
}

.column {
  float: left;
  width: 33.33%;
}

/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}

[class^='col'] {
  position: relative;
}

.overflow-visible {
  white-space: initial;
  font-size: 16px;
}

.inline-block {
  display: inline-block;
}

.agentHdr {
  background-color: white;
}

.hdrText {
  line-height: 30px;
  text-align: right;
  padding-top: 3px;
  text-decoration: underline;
  font-size: 12px;
}

.btnSignOut {
  background-color: $color-nero;
  border-color: $color-bright-pink;
  color: $color-white;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 120px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-style: solid;
  border-radius: 3px;
  border-width: 3px;
  margin-bottom: 10px;
  margin-left: 8px;
  font-size: 12px;
}

.btnSignOut:hover {
  background-color: $color-bright-pink;
  color: $color-white;
}

.bodyBackground {
  background-color: $color-white-smoke;
}

.pinkHr {
  border-top: 3px;
  border-top-style: solid;
  border-top-color: $color-bright-pink;
}

.disabled .pinkHr {
  border-top-color: $color-creased-silver;
}

.disabled .pinkHr h3 {
  border-top-color: $color-creased-silver;
}

.grayHr {
  border-top: 1px;
  border-top-style: solid;
  border-top-color: $color-gainsboro;
  margin-left: -1.5rem; /* counteracts parent container with .p-4 */
  margin-right: -1.5rem; /* counteracts parent container with .p-4 */
}

.peptoPink {
  color: $color-bright-pink;
}

.createCall {
  background-color: $color-bright-pink;
  color: white;
  font-size: 30px;
}

.boldInfo {
  font-size: 32px;
  font-weight: bold;
}

.okGreen {
  color: $color-olivine;
}

.dangerRed {
  color: $color-cinnabar;
}

.btnGetCodes {
  width: 100%;
}

.btnGetCodesInactive {
  background-color: #b9b6b6;
  display: block;
  width: 100%;
}

span.disable-links {
  pointer-events: none;
}

.regularBtn {
  background-color: $color-bright-pink;
  border-color: $color-bright-pink;
  padding: 5px 20px;
  color: white;
  letter-spacing: 1px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-style: solid;
  border-radius: 3px;
  text-transform: uppercase;
  min-width: 97px;
  height: 27px !important;
  font-size: 12px;
  max-height: 38px !important;
  margin-top: 0px !important;
}

.cancelBtn {
  background-color: $color-light-gray;
  color: $color-white;
}

.txtPasswdLink {
  color: $color-bright-pink;
  float: right;
  font-size: 10px;
  text-decoration: underline;
}

.boldMe {
  font-weight: 700;
}

.addButton {
  float: right;
}

.accordionBtnTxt {
  display: inline;
  width: 60%;
}

.accordion a .material-icons,
.accordion a.material-icons {
  cursor: pointer;
}

.matDesignIcon {
  width: 40%;
}

.logo_size {
  height: 48px;
  width: 188px;
  padding-top: 5px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.login_logo_size {
  max-height: 50px;
  margin-top: 50px;
}

.lnkPrivacy {
  color: #b4b0b0;
  float: left;
  font-size: 10px;
  margin-top: 20px;
}

.card {
  background-color: $color-white;
  width: 100%;
}

.cardHdr {
  background-color: $color-white-smoke;
  width: 85%;
}

.ubuntu600 {
  font-weight: 500;
  font-size: 20px;
}

.ubuntu400 {
  font-weight: 400;
  font-size: 20px;
}

.centerItem {
  margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.padBottom {
  padding-bottom: 30px;
}

.horizonCares {
  width: 100%;
  padding-bottom: 20px;
  padding-top: 30px;
}

.imgHorizon_size {
  height: auto;
  width: auto;
  max-height: 55px;
  padding-top: 5px;
}

.centerImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.padBottomSm {
  padding-bottom: 15px;
}

.flex-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.material-icons.md-inline {
  font-size: inherit;
  height: inherit;
  line-height: inherit;
  width: inherit;
  vertical-align: bottom;
}

.material-icons.md-10 {
  font-size: 10px;
}

.material-icons.md-8 {
  font-size: 8px;
}

.material-icons.md-12 {
  font-size: 12px;
}

.material-icons.md-14 {
  font-size: 14px;
}

.material-icons.md-16 {
  font-size: 16px;
}

.material-icons.md-18 {
  font-size: 18px;
}

.material-icons.md-24 {
  font-size: 24px;
}

.material-icons.md-36 {
  font-size: 36px;
}

.material-icons.md-48 {
  font-size: 48px;
}

.col-centered {
  margin: 0;
  position: relative;
  text-align: center;
  max-width: 100%;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -100px;
}

.boldModal {
  font-size: 20px;
  font-weight: bolder;
}

.citystate {
  list-style-type: none;
  text-align: left;
  margin: 0;
  padding: 0;
}

.citystate li {
  display: inline-block;
  font-size: 20px;
}

.btnSupport {
  position: fixed;
  top: 130px;
  right: 0px;
}

.bigger {
  font-size: 20px;
}

.font-22 {
  white-space: initial;
  font-size: 16px;
}

.checkboxReg {
  display: block;
  padding-left: 5px;
}

.noMarginBotton {
  margin-bottom: 1px;
}

.regLinkPadLeft {
  padding-left: 18px;
}

.dashboard {
  position: relative;
}

// .dashboard {
//   padding-left: 25px;
//   padding-right: 25px;
//   padding-top: 20px;
//   bottom: 0;
//   display: flex;
//   flex-direction: column;
//   min-height: 100vh;
//   height: 100%;
// }

// .dashboard-perf {
//   padding-top: 20px;
//   bottom: 0;
//   display: flex;
//   flex-direction: column;
// }

.perf-pad {
  padding-left: 25px;
  padding-right: 25px;
}

.dashboard-alt {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 20px;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.nav {
  background: $color-nero;
}

.bkGround {
  background: $color-nero;
}

.altBkGround {
  background-color: $color-white-smoke;
}

.ltBackground {
  color: $color-creased-silver;
}

p[routerlink] {
  cursor: pointer;
}

.squish-left {
  position: relative;
  width: 100%;
}

.squish-right {
  padding-right: 0;
  margin-right: 0;
  width: 100%;
  position: relative;
}

.panel {
  background-color: $color-white;
  padding: 20px;
}

.addTopPadding {
  padding-top: 20px;
}

h3.narrow {
  font-weight: 200;
}

.pt-0 {
  padding-top: 0px;
}

.pt-1 {
  padding-top: 1px !important;
}

.pt-2 {
  padding-top: 2px !important;
}

.pt-3 {
  padding-top: 3px !important;
}

.pt-4 {
  padding-top: 4px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-6 {
  padding-top: 6px !important;
}

.pt-7 {
  padding-top: 7px !important;
}

.pt-8 {
  padding-top: 8px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-25 {
  padding-top: 20px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-1 {
  padding-bottom: 1px !important;
}

.pb-2 {
  padding-bottom: 2px !important;
}

.pb-3 {
  padding-bottom: 3px !important;
}

.pb-3rem {
  padding-bottom: 3rem !important;
}

.pb-4 {
  padding-bottom: 4px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pl-0 {
  padding-left: 0px;
}

.pl-1 {
  padding-left: 1px;
}

.pl-2 {
  padding-left: 2px;
}

.pl-3 {
  padding-left: 3px;
}

.pl-4 {
  padding-left: 4px;
}

.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pr-0 {
  padding-right: 0px;
}

.pr-1 {
  padding-right: 1px;
}

.pr-2 {
  padding-right: 2px;
}

.pr-3 {
  padding-right: 3px;
}

.pr-4 {
  padding-right: 4px;
}

.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 25px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-1 {
  margin-bottom: 1px;
}

.mb-2 {
  margin-bottom: 2px;
}

.mb-3 {
  margin-bottom: 3px;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mt-0 {
  margin-top: 0px;
}

.mt-1 {
  margin-top: 1px !important;
}

.mt-2 {
  margin-top: 2px !important;
}

.mt-3 {
  margin-top: 3px !important;
}

.mt-4 {
  margin-top: 4px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.ml-1 {
  margin-left: 1px;
}

.ml-2 {
  margin-left: 2px;
}

.ml-3 {
  margin-left: 3px;
}

.ml-4 {
  margin-left: 4px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.mr-auto {
  margin-right: auto;
}

.mr-1 {
  margin-right: 1px;
}

.mr-2 {
  margin-right: 2px;
}

.mr-3 {
  margin-right: 3px;
}

.mr-4 {
  margin-right: 4px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.font-30 {
  font-size: 30px;
}

.font-25 {
  font-size: 25px;
}

.font-23 {
  font-size: 23px;
}

.font-20 {
  font-size: 20px;
}

.font-19 {
  font-size: 19px;
}

.font-18 {
  font-size: 18px;
}

.font-16 {
  font-size: 17px;
}

.font-15 {
  font-size: 15px;
}

.font-14 {
  font-size: 14px;
}

.font-13 {
  font-size: 13px;
}

.font-12 {
  font-size: 12px;
}

.font-10 {
  font-size: 10px;
}

.font-9 {
  font-size: 9px;
}

.collapsible-header.active i.more {
  display: none;
}

.collapsible-header.active i.less {
  display: block;
}

.collapsible-header i.less {
  display: none;
}

.pnl-height {
  height: 51px;
}

.create-call-txt-input {
  width: 100%;
  background-color: $color-eclipse;
  border-width: 0px;
  color: white;
}

.flex-container {
  display: flex;
  flex-direction: row;
}

.white-background {
  background-color: white !important;
}

.table-icon {
  height: 15px;
}

.icon-btn {
  max-height: 70px;
  padding-bottom: 2px;
}

.same-line {
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}

.politespace-proxy {
  position: relative;
  overflow: hidden; /* clearfix for floating siblings */
  display: inline-block;
}

.politespace-proxy-val {
  display: none;
}

.politespace-proxy.active.notempty > .politespace-proxy-val {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 1;
}

/* Safari 9 has a weird top/bottom margin on form elements inside of politespace proxies */
/* This caused issues with proxy positioning.
.politespace-proxy > input {
  margin-top: 0;
  margin-bottom: 0;
}*/
.politespace-proxy.active.notempty input {
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.politespace-proxy.active.notempty input[type='number'] {
  -moz-appearance: textfield;
}

.politespace-proxy.active.notempty input[type='number']::-webkit-inner-spin-button,
.politespace-proxy.active.notempty input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.timer {
  background-color: $color-bright-pink;
  color: white;
  border-radius: 5px;
  padding: 0 15px;
  border: solid 2px $color-white;
}

.standard-icon {
  max-height: 25px;
  min-width: 27px;
}

.admin-form-img {
  height: 27px;
}

.user-header {
  border-width: 1px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-style: solid;
  border-color: $color-light-gray;
  background-color: white;
  max-height: 60px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 15px;
}

.user-listing {
  width: 100%;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 15px;
  padding-bottom: 10px;
  height: 55px;
}

.striped {
  background-color: $color-white-smoke !important;
}

.card {
  border: none !important;

  min-height: 75px;
}

.card-header {
  border-bottom: none !important;
}

.card-header .col-md-12 {
  padding-left: 0;
  padding-right: 0;
}

.selected-items {
  background-color: $color-old-line-ivory !important;
}

.user-rows {
  max-height: 20px;
  font-size: 15px;
}

.top-bar-dropdown li a {
  border: none;
  background: none;
  font-size: 14px;
}

.tiny-tooltip {
  height: 12px;
  margin: -2px 0 0 5px;
}

.line-chart-container {
  padding-right: 20px;
}

.select-filter {
  margin-left: 15px;
  width: 250px !important;
}

.admin-form {
  padding: 40px;
}

.static-info {
  border: 1px solid #dcdcdc;
  border-radius: 5px;

  p {
    font-size: 15px;
  }
}

.admin-form table th,
.admin-form table td {
  font-size: 15px;
}

.admin-form select.custom-select,
.admin-form input[type='text'].form-control {
  height: 36px;
  font-size: 15px;
  color: $color-black !important;
  font-weight: 400 !important;
}

body .admin-form .button--primary,
.admin-form .button--default {
  height: 36px !important;
  min-width: 120px;
  max-height: none !important;
  font-size: 14px !important;
}

body .admin-form .btnStyle3 {
  height: 48px !important;
  min-width: auto;
  max-height: none !important;
  font-size: 14px !important;
}

body .admin-form .button--primary.squished {
  min-width: auto !important;
}

.admin-form label {
  font-size: 15px;
  margin-bottom: 1px;
}

.textBlack {
  color: black;
}

.admin-form .textBlack {
  font-size: 15px;
}

.rowEditMode {
  background-color: #e2e2e2;
}

.force-to-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.force-to-top {
  position: fixed;
  top: 95px;
}

.force-notification-to-bottom {
  position: fixed;
  bottom: 56px;
  left: 55px;
}

.position-agent-notifiction {
  position: absolute;
  bottom: 3%;
  right: 22%;
}

.agent-avater-container {
  position: relative;
}

.force-notification-to-bottom-expand {
  position: fixed;
  bottom: 223px;
  left: 55px;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.textWhite {
  color: white;
}

.black-bg {
  background-color: $color-tar;
}

.userRole {
  background-color: $color-bright-pink;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 11px;
}

.dropDown {
  width: 100%;
}

.message-green {
  background-color: #d7e7c9;
  border-radius: 3px;
  padding: 12px;
  margin-bottom: 15px;
  font-size: 15px;
}

.message-green p {
  font-size: 15px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 0;
}

.message-red {
  border-color: #f5c6cb;
  background-color: #f8d7da;
  border-radius: 3px;
  padding: 12px;
  margin-bottom: 15px;
}

.message-red p {
  font-size: 15px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 0;
  color: #721c24;
}

.control-inline {
  display: block;
  float: left;
  width: 18.6%;
  margin-top: 5px;
}

.form-group label {
  margin-bottom: 2px;
}

select.custom-select,
input[type='password'].form-control,
input[type='text'].form-control,
.form-group input[type='text'] {
  font-size: 14px;
  font-weight: bold;
  border-radius: 3px;
  border-color: $color-gainsboro;
  height: 29px;
}

.form-control:disabled,
.form-control[readonly] {
  background: $color-concrete !important;
  color: $color-creased-silver !important;
}

/* .panel-body select,
.panel-body input[type="text"].form-control {
  font-size: 15px !important;
  color: $color-creased-silver;
  font-weight: 300;
  height: 50px;
}

.large-label {
  font-size: 12px;
  margin-bottom: 0;
} */

.pharmacy-container-new {
  border-radius: 3px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.pharmacy-container-new .pharmacy-header {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.pharmacy-container-new select,
.pharmacy-container-new input[type='text'] {
  font-size: 15px;
  color: $color-creased-silver;
  font-weight: bold;
}

.pharmacy-container-new label {
  font-size: 15px;
  margin-bottom: 1px;
}

.pharmacy-container-new .panel-body {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.pharmacy-container-new .cancel-50 {
  background: black !important;
  border-color: black !important;
}

.pharmacy-container-new .cancel-50:hover {
  border-color: $color-bright-pink !important;
  background: $color-bright-pink !important;
}

.pharmacy-container-new button.px-50-test {
  min-width: 140px;
}

.pharmacy-container-new .pharmacy-header .main-scrn-titlem {
  padding: 10px;
  font-size: 22px;
}

input[type='password'].form-control.light,
input[type='text'].form-control.light {
  font-weight: normal;
  height: 36px;
}

.agent-tabs {
  background-color: unset;
  margin-bottom: 1px;

  .nav-link {
    transform: translate(0, 3px);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: $color-gainsboro;
    border-bottom: none;
    margin-right: 4px;
    margin-bottom: 4px;
    font-size: 15px;
    background-color: #f6f6f6;
    padding-top: 12px;
    padding-bottom: 8px;
    color: black;

    &:disabled {
      .tab-title {
        color: $color-creased-silver;
      }
    }

    &:hover,
    &:focus {
      color: black;
      background-color: #f6f6f6;
    }

    &.active {
      border-top-color: $color-bright-pink;
      border-top-width: 5px;
      border-bottom: 2px solid white;
      background-color: white;
      padding-top: 8px;
      margin-bottom: 0px;
      color: black;
    }

    .tab-title {
      font-size: 15px;
      font-weight: initial;
    }
  }
}

#rx-requests-tabs {
  .nav-tabs {
    border-bottom: none;
  }
}

.agent-checkbox-label::before {
  border-color: $color-gainsboro !important;
  background-color: white !important;
  border-radius: 10% !important;
}

.agent-checkbox:checked ~ .agent-checkbox-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Crect width='100%' height='100%' rx='15%' ry='15%' fill='black'/%3E%3C/svg%3E") !important;
}

.form-group label.disabled,
.disabled-title,
.agent-tabs .nav-link.disabled,
select.agent-select:disabled {
  color: $color-creased-silver !important;
}

.agent-modal {
  border-radius: 2px;
}

.payor-confirmation-modal {
  position: absolute !important;
  z-index: 1 !important;
}

.payor-confirmation-modal .modal-content,
.dispense-code-modal .modal-content {
  border-radius: 2px;
}

.payor-confirmation-modal .custom-control {
  padding-left: 17px;
}

.dispense-code-modal {
  .modal-dialog {
    width: 700px;
    max-width: 800px !important;
  }
}

.dispense-code-modal-title {
  font-size: 24px !important;
}

.dispense-code-modal {
  font-size: 15px;
}

.dispense-code-modal .label {
  font-weight: bold;
}

.agent-dispense-modal {
  .modal-dialog {
    width: 500px;
    height: 650px;
  }
}

.payor-confirmation-modal-backdrop.modal-backdrop.show {
  position: absolute !important;
  opacity: 0.6 !important;
  height: unset;
  width: unset;
  z-index: unset !important;
}

.dispense-code-modal-backdrop.modal-backdrop.show {
  opacity: 0.6 !important;
  z-index: unset !important;
}

.bold-placeholder {
  &::placeholder {
    font-weight: bold !important;
  }
}

.input-icon {
  position: relative;

  & > input {
    padding-left: 25px;
    padding-right: 0;
  }

  & > i {
    position: absolute;
    display: block;
    transform: translate(0, -50%);
    top: 50%;
    pointer-events: none;
    width: 25px;
    text-align: center;
    font-style: normal;
    font-weight: normal;

    &.disabled {
      font-weight: bold;
      color: $color-creased-silver;
    }
  }
}

.btn-sm-1.magnified {
  height: 50px !important;
  max-height: none;
  font-size: 14px;
  outline: none !important;
}

input.magnified[type='radio'],
input.magnified[type='checkbox'] {
  zoom: 1.5;
}

.input-radio {
  display: inline-block;
  position: relative;
  height: 22px;
  width: 22px;
  background-color: #ffffff;
  border: solid 1px #979797;
  border-radius: 100%;
}

.input-radio--checked {
  &:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    height: 16px;
    width: 16px;
    background-color: #1a1a1a;
    border-radius: 100%;
  }
}

h3.bg-gray {
  background: $color-concrete;
}

.icon-sm {
  height: 30px;
  width: 30px;
}

.icon-xs {
  height: 20px;
  width: 20px;
}

.no-gutter-right {
  padding-right: 0 !important;
}

.no-gutter {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.btn-square {
  padding: 8px 20px 10px 20px;
  border-left: solid 1px $color-concrete;
  border-right: solid 1px $color-concrete;
  font-size: 12px;
  display: inline-block;
  text-align: center;
  cursor: not-allowed;
}

.btn-square img {
  margin-bottom: 5px;
}

.btn-square.end-call {
  display: inline-block;
  float: right;
  padding-top: 14px;
  padding-bottom: 14px;
}

.btn-square.end-call .button--primary {
  height: 32px;
  font-size: 10px;
  padding: 4px 10px 4px 10px;
  margin-top: 0;
}

.btn-square.end-call .button--primary img {
  margin-right: 7px;
  height: 15px;
  width: 15px;
}

a.btn-square {
  color: $color-creased-silver;
}

a.btn-square:hover {
  background: $color-white-smoke;
}

tr.topBorder td {
  border-top: solid 1px $color-concrete !important;
}

tr.rowVerticalCenter td {
  font-weight: bold;
  vertical-align: middle;
}

tr.noBorder td {
  border-top: none;
}

.add-container {
  display: block;
  font-size: 14px;
  cursor: pointer;
  border: none;
  background: none;

  &.disabled {
    color: $color-creased-silver !important;
    pointer-events: none;
    cursor: default;
  }
}

.noglow {
  outline: none !important;
  box-shadow: none !important;
  outline-color: transparent;
  outline-style: none;
  border: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;

  &.pharmacy-select {
    border: 1px solid #ced4da !important;
    border-radius: 3px !important;
    border-color: $color-gainsboro !important;
  }
}

.brand-container .checkbox-inline {
  margin-right: 20px;
}

.pink-bg {
  background-color: $color-bright-pink;
}

.admin-avatar {
  margin-top: 0px;
}

.avatar-container {
  width: 225px;
}

.avatar-container .userRole {
  font-size: 11px;
  padding: 5px 10px;
  border-radius: 3px;
  text-transform: uppercase;
}

.avatar-container .dropdown-menu {
  border-radius: 3px;
}

.admin-avatar .avatar-img-container {
  padding-right: 7px;
}

.dropdown-menu.dropdown-menu-right.show {
  min-width: 214px;
  overflow: hidden;
}

.agent-avatar .dropdown-menu.dropdown-menu-right.show {
  top: 37px !important;
}

.avatar-container .dropdown-menu a {
  font-size: 14px;
  cursor: pointer;
  padding: 12px 15px;
  display: inline-block;
}

.avatar-container .dropdown-toggle {
  padding: 5px 8px;
  display: inline-block;
  cursor: pointer;
}

.avatar-container .dropdown-toggle img {
  width: 11px;
}

.dropdown-toggle::after {
  display: none !important;
}

.modal .modal-content {
  background: $color-concrete;
}

.transparent-bg {
  .modal .modal-content {
    background: transparent;
  }
}

.change-pwd-modal {
  .modal-dialog {
    width: 500px;
    height: 650px;
    max-width: 500px;
  }

  .modal-content {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
  }
}

.verify-info-modal {
  .modal-dialog {
    width: 600px;
    height: 650px;
    max-width: 600px !important;
  }
}

.modal-select-avatar {
  .modal-dialog {
    width: 500px;
    height: 650px;
    max-width: 500px;
  }

  .modal-body {
    padding-left: 50px;
    padding-right: 50px;
  }
}

.modal-need-help {
  .modal-dialog {
    width: 426px;
  }

  .modal-header {
    background: $color-white;

    > button.close {
      background: none !important;

      span {
        color: $color-nostalgic-evening;
        font-weight: 300;
      }
    }
  }

  .modal-body {
    padding-left: 50px;
    padding-right: 50px;
  }

  .modal-content {
    background: $color-white;
  }
}

.sign-out-modal {
  .modal-dialog {
    width: 450px;
    height: 650px;
    max-width: 500px;
  }

  &--mobile {
    .modal-dialog {
      width: 90%;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    .modal-content {
      height: 200px;
    }

    .modal-header {
      padding-bottom: 0;
    }

    .modal-body {
      padding-bottom: 0px !important;
    }

    .spaced-buttons {
      padding-bottom: 30px !important;
    }
  }
}

.robin-hood-modal {
  .modal-dialog {
    width: 500px;
    height: 650px;
  }
}

.agent-end-call-modal {
  .modal-dialog {
    width: 500px;
    height: 400px;
  }
}

.modal .modal-header button.close {
  background: $color-gainsboro;
  padding: 5px;
  border-radius: 50px;
  height: 25px;
  width: 25px;
  margin: 0px;
}

.modal .modal-header button.close span {
  display: block;
  margin-top: -5px;
  line-height: 25px;
}

.export-down {
  color: white;
  padding-left: 1px;
}

.pt-access-txt {
  color: white;
  margin-top: 50px;
  margin-bottom: 0;
  font-size: 2.25rem;
}

.hello-welcome {
  font-size: 1.65rem;
  font-weight: bold;
}

.main-scrn-title {
  font-size: 1.5rem;
  font-weight: 400;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.modal-conf-center {
  position: absolute;
  left: 20%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.modal-chgpwd-center {
  position: absolute;
  left: 20%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.accordion-border {
  border-width: 1px;
  border-style: solid;
  border-color: $color-white-lilac !important;
  border-radius: 3px;
}

.px-50-test {
  height: 50px !important;
}

.underlineMe {
  text-decoration: underline;
}

.cancel-50 {
  background: black !important;
  border-color: black !important;
}

.cancel-50:hover {
  background: $color-bright-pink !important;
  border-color: $color-bright-pink !important;
  color: $color-white !important;
}

.btn-nav {
  background: $color-white;
  border: solid 1px $color-very-light-gray;
  text-transform: uppercase;
  margin: 15px 0;
  padding: 5px 14px;
  cursor: pointer;
}

.btn-nav:disabled {
  cursor: auto;
}

.btn-nav.left {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.btn-nav.right {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.btn-outline-light {
  color: #1f2224;
  box-shadow: none;
  background: #ffffff;
  border: solid 1px #cccccc;

  &:hover,
  &:focus {
    color: #1f2224;
    box-shadow: none;
    background: #ffffff;
    border: solid 1px #cccccc;
  }

  &.disabled,
  &:disabled {
    color: #1f2224;
    background: #ffffff;
  }
}

.btn-link {
  color: $color-bright-pink;
  text-transform: capitalize;

  &:hover,
  &:focus {
    color: $color-bright-pink;
  }
}

.btn-hidden {
  display: none !important;
}

.all-caps {
  text-transform: uppercase !important;
}

.spaced-buttons button {
  margin: 0 10px !important;
}

.borders {
  border: 1px;
  border-style: solid;
  border-color: $color-moveable-oyster;
  border-radius: 3px;
}

.borders-u {
  border: 1px;
  border-style: solid;
  border-color: $color-moveable-oyster;
  border-radius: 3px;
  border-top-color: transparent;
}

.border-left {
  border-left: 1px;
  border-style: solid;
  border-color: $color-moveable-oyster;
}

.avatar-right-col {
  float: right;
}

.max-height-fix {
  max-height: 48px !important;
}

.dropdown-item {
  cursor: pointer;
  font-size: 14px;
}

.document-container .row {
  padding: 20px 0 40px 0;
}

.document-container .row.white {
  background: white;
}

.document-container .icon {
  height: 32px;
  width: 32px;
  vertical-align: top;
  margin-top: -5px;

  &--print {
    height: 50px;
    width: 50px;
    margin-top: -14px;
  }
}

.table-centered tbody tr td {
  border: none;
}

.document-container img.hovered {
  display: none;
}

.document-container a:hover img {
  display: none;
}

.document-container a:hover img.hovered {
  display: inline-block;
}

.list_container {
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden !important;
}

.list_container_pharm {
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden !important;
}

.item_direction {
  direction: ltr;
}

.noScroll {
  overflow: hidden !important;
  -ms-overflow-style: auto;
}

.spin-me {
  position: absolute;
  z-index: 100;
  left: 45%;
  top: 45%;
}

.no-outine {
  outline: none;
}

.state {
  fill: none;
  stroke: $color-dark-gray;
  stroke-width: 1;
}

.state:hover {
  fill-opacity: 0.5;
}

.row.monitoring-hdr li,
.monitor-toggle-off {
  border-bottom: none;
  color: $color-silver;
}

.row.monitoring-hdr li.monitor-toggle-on {
  border-bottom: solid;
  border-bottom-width: 3px;
  color: black;
  height: 47px;
}

.insight-bg {
  background-image: url('../../assets/images/voroni.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: $color-white;
  border-color: lightgray;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
}

.monitoring-hdr {
  min-height: 50px;
  height: 50px;
  border-bottom: solid;
  border-bottom-width: 1px;
  border-bottom-color: $color-silver;
}

#pharmCollapse {
  background-color: white;
  padding: 2px;
  border: solid;
  border-width: 1px;
  border-color: $color-silver;
}

.exportBtn {
  background-color: black;
  border-color: black;
  padding: 1px 20px;
  color: white;
  letter-spacing: 1px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-style: solid;
  border-radius: 3px;
  text-transform: uppercase;
  min-width: 97px;
  height: 50px !important;
  font-size: 14px !important;
  margin-top: 0px !important;
  width: auto;
  display: inline-block;
  vertical-align: top;
}

.exportBtn:hover,
.exportBtn:focus,
.exportBtn:active {
  background-color: $color-bright-pink;
  border-color: $color-bright-pink;
  padding: 6px 20px;
  color: white;
  letter-spacing: 1px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-style: solid;
  border-radius: 3px;
  text-transform: uppercase;
  min-width: 97px;
  height: 50px !important;
  font-size: 14px !important;
  margin-top: 0px !important;
  width: auto;
}

.scrollable-menu {
  height: auto;
  max-height: 600px;
  overflow-x: hidden;
  overflow-y: auto !important;
  transform: none !important;
  top: 30px !important;
  left: -65px !important;
}

.chart-contents {
  height: 100%;

  &__no-data-label {
    @include center-element();
    font-size: 22px;
  }
}

.icon-shadow svg {
  width: 100% !important;
  height: 100% !important;
}

.edit-dropdown {
  display: inline-block;
  height: 50px;
  background-color: white;
  vertical-align: middle;
  font-size: 15px;
  line-height: 50px;
  padding: 0 10px;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
}

.body-content {
  @include media-breakpoint-down(sm) {
    margin-top: 75px;
    margin-left: 0 !important;
    width: 100%;
  }

  @include media-breakpoint-between(sm, lg) {
    margin-top: $header-height;
    margin-left: 0 !important;
    height: calc(100vh - #{$header-height});
  }

  @include media-breakpoint-up(lg) {
    margin-top: $header-height;
    margin-left: 16.666667%;
    height: calc(100vh - #{$header-height});
  }

  &--default-header {
    margin-top: 0;
    height: 100vh;
  }

  &--simple-header {
    margin-top: 0;
    height: calc(100vh - 100px);
  }
}

.body-content--full-width {
  margin-left: 0;
}

.navigation-container {
  height: 100%;
  position: fixed !important;
  left: 0;
  background-color: $color-black;
  z-index: 1;

  @include media-breakpoint-down(sm) {
    z-index: 0;
    background-color: unset;
  }

  &--open {
    width: 100%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;

    @include media-breakpoint-down(md) {
      max-width: 100%;
      flex: unset;
      background-color: transparent;
      z-index: 3;
    }
  }

  &--mobile-collapsed {
    @include media-breakpoint-down(sm) {
      width: 0% !important;
      z-index: 2;
    }
  }
}

.ps__thumb-y {
  min-width: 10px;
}

.u-no-margin {
  margin: 0 !important;
}

.create-rx-request-modal {
  .modal-dialog {
    max-width: 650px !important;
    width: 650px;
  }

  &--mobile[role='dialog'] {
    display: flex !important;
    align-items: center;

    .modal-dialog {
      width: 95%;
    }

    .content {
      padding: 30px 20px !important;
    }

    .content__title {
      font-size: 22px !important;
      margin-bottom: 20px;
    }

    input {
      font-size: 14px !important;
      height: 40px !important;
    }

    .content-buttons {
      .button {
        font-size: 14px !important;
        height: 40px !important;
      }
    }
  }
}

// bootstrap datepicker
.ngb-dp-arrow-btn {
  color: $color-bright-pink !important;
}

.ngb-dp-navigation-select {
  select {
    &:focus {
      border-color: $color-bright-pink;
      box-shadow: none;
    }
  }
}

.ngb-dp-weekday {
  color: $color-black !important;
  font-weight: 500 !important;
}

.ngb-dp-day {
  &:focus {
    outline: none;
  }

  .bg-primary {
    background-color: $color-bright-pink !important;
  }
}

.ngb-dp-header {
  .btn {
    box-shadow: none;
  }
}

// End bootstrap datepicker

// Old agent panel
.filter-form label {
  font-size: 15px;
  font-weight: 700;
}

.filter-form label {
  margin-left: 5px;
}

.filter-form label,
.filter-form .custom-select {
  margin-right: 10px;
}

.filter-form .custom-select {
  margin-right: 10px;
}

.filter-form .custom-input-sm {
  border-radius: 0;
  margin-right: 5px;
  font-size: 12px;
}

.custom-checkbox {
  display: flex;

  .custom-control-input {
    height: 25px;
  }

  .custom-control-label {
    padding-left: 8px;
  }
}

.create-call-bar {
  margin-top: 20px;
}

.create-call-bar .call-caption {
  color: $color-white;
  font-size: 15px;
  text-align: left;
  line-height: 65px;
}

.create-call-bar .call-info {
  background-color: black;
  width: 100%;
  height: $agent-call-bar-height;
  padding-top: 2px;
}

.create-call-bar .call-info input[type='text'].form-control.custom-input-sm {
  width: 96%;
  margin-top: 9px;
  color: $color-white !important;
}

.create-call-bar .call-info button {
  margin-top: 9px;
}

.create-call-bar .call-info.white {
  background-color: $color-white;
  width: 100%;
}

.header-today {
  font-size: 18px;
  margin-left: 12px;
}

.create-call-bar .call-info.white td {
  color: $color-black;
  vertical-align: middle;
  font-size: 14px;
  padding-right: 20px;
}

.create-call-bar .call-info .custom-input-sm {
  width: 90%;
  background-color: $color-eclipse;
  border-width: 0px;
  color: white;
  height: 34px;
  font-weight: normal;
}

.create-call-bar {
  height: 111px;
  width: 100%;
  overflow: visible;
}

.call-bar-btn {
  margin-top: 9px !important;
}

.patient-panel {
  margin-top: -7px;
}

.patient-panel.pinkHr {
  border-radius: 5px;
  border-top-color: $color-bright-pink;
  border-top-width: 3px;
}

.patient-panel .grayHr {
  border-width: 0.25px;
}

.patient-panel .patient_table td {
  border: none;
}

.patientOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: $color-white;
  opacity: 0.3;
  top: 0;
  left: 0;
  z-index: 500;
}

.patient-panel h3 {
  text-align: center;
  width: 100%;
  text-transform: uppercase;
  font-size: 16px;
  padding: 7px;
  margin-bottom: 0px;
}

.disabled .patient-panel h3 {
  color: $color-creased-silver;
  background: $color-concrete;
}

.button--primary.magnified,
.patient-panel .button--primary {
  height: 50px !important;
  max-height: none;
  font-size: 14px;
}

.patient-dispense-btn {
  min-width: 40px !important;
}

app-square-checkmark.disabled {
  .square-checkbox__label {
    color: #bdbdbd !important;
  }

  .square-checkbox__label::before {
    background-color: #f0f0f0 !important;
  }
}

.btn-link {
  color: inherit;
}

.text-link {
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.no-wrap {
  white-space: nowrap;
}

.bs-table {
  @extend .table-responsive;
  position: relative;

  table {
    @extend .table;
    @extend .table-striped;
    @extend .table-hover;
    font-size: 13px;
    margin: 0 0 5px;

    th,
    td {
      border: none;
      padding: 5px;
    }
  }

  thead {
    tr {
      th {
        border-bottom: none;
      }
    }

    tr.thead {
      th {
        color: #fff;
        background-color: #000000;
        border-color: #000000;
        text-transform: uppercase;
        padding: 12px 5px;
      }

      th.sortable {
        cursor: pointer;
      }
    }

    tr.tfilter {
      .form-control {
        border: 1px solid #cccccc;
        border-radius: 0;
        font-size: 13px;
        height: 34px;
        padding: 5px;
      }

      .input-group {
        .btn {
          height: 34px;
        }
      }
    }
  }

  .empty-table {
    text-align: center;
    vertical-align: middle;
    background: transparent;
    box-shadow: none;
    border: none;

    p {
      margin: 20px 0 0;
      font-size: 30px;
      font-weight: normal;
      color: #b9b9b9;
    }

    .alert {
      padding: 0;
      margin: 16px;
    }
  }

  .text-control__input-and-edit {
    height: 34px !important;
  }

  .select-control__control-container {
    height: 34px !important;

    .ng-select.ng-select-single .ng-select-container {
      height: 34px !important;
      min-height: 34px !important;
      border-radius: 0;
    }
  }
}

formly-form {
  input[type='password'].form-control,
  input[type='text'].form-control,
  .form-group input[type='text'],
  .form-control {
    font-size: 13px;
    height: 36px;
    border-color: #ced4da;
    border-width: 1px;
    padding: 6px 12px;

    &:focus,
    &:hover {
      height: 36px;
      border-color: #999999;
      border-width: 1px;
    }

    &.is-invalid {
      border-color: #dc3545;
    }
  }
}

.tooltip--retail {
  .arrow:before {
    border-bottom-color: #d8d8d8;
  }

  .tooltip-inner {
    max-width: initial;
    color: #1a1a1a;
    background-color: #d8d8d8;
    text-align: left;
  }
}

.app-table-retail {
  .form-control {
    height: 25px;
    font-size: 12px !important;
  }

  .btn {
    height: 25px;
    padding: 0 4px;
  }
}

.retail {
  background: #f8f8f8;
}

ng-dropdown-panel {
  height: auto !important;
  border: none !important;
  padding: 0.4rem !important;
}

.app-retail-data-grid {
  .bs-table table {
    min-height: 90px;
  }

  .bs-table thead tr.thead th {
    color: black;
    background-color: transparent;
    text-transform: none;
    padding: 10px 5px 5px;
  }

  .bs-table thead tr.tfilter .form-control {
    height: 25px;
    padding: 3px 6px;
  }

  .ng-select.form-control {
    padding-top: 6px !important;
    border: none !important;
  }

  .bs-table thead tr.tfilter .input-group {
    top: -9px;
  }

  .bs-table thead tr.tfilter .input-group .btn {
    height: 25px;
    padding: 3px 6px;
    font-size: 11px;
  }

  .bs-table thead tr.tfilter .ng-select.ng-select-single .ng-select-container {
    height: 25px !important;
    min-height: 25px !important;
  }

  .bs-table thead tr.tfilter .ng-value-label {
    font-size: 13px;
  }
}

body > .ng-dropdown-panel {
  width: auto !important;
}

.self-serve-control-panel {
  app-data-grid {
    height: calc(100% - 60px);
    overflow: auto;
  }

  app-shared-paginator {
    display: block;
    margin-top: 10px;
  }
}

experience {
  .spinner-border {
    color: white !important;
  }
}

@import 'themes/pbg-theme';

.float-left {
  @extend .float-start;
}

.float-right {
  @extend .float-end;
}

.text-left {
  @extend .text-start;
}

.text-right {
  @extend .text-end;
}
