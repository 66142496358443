@import 'abstracts/variables';
@import 'abstracts/variables-dark';
@import 'abstracts/breakpoints';
@import 'abstracts/mixins';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.page-content {
  padding: 40px;
  background-color: $color-white-smoke;
  height: 100%;

  &__title {
    font-size: 1.5rem;
    font-weight: 400;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 20px;

    &--card {
      display: flex;
      justify-content: center;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
  }

  input[type='text'].form-control {
    height: 36px;
  }
}

.spaced-container {
  padding: 0 10px !important;
}

.nav-collapsed {
  margin-left: 15px !important;

  @include media-breakpoint-up(lg) {
    width: calc(100% - 15px) !important;
    max-width: calc(100% - 15px) !important;
  }
}

html {
  font-size: 16px;

  @media (max-width: 1440px) {
    font-size: 14px;
  }
}

body {
  background-color: $color-white-smoke !important;
  font-family: 'Poppins', sans-serif;
}

.dark-popover {
  background-color: black !important;
  font-size: 8px;

  &--right {
    .arrow::after {
      border-right-color: black !important;
    }
  }

  em {
    font-weight: 600;
    display: block;
  }

  .popover-body {
    color: $color-white;
  }

  .arrow::after {
    border-top-color: black !important;
  }
}

.pharmacy-scorecard-popover {
  background-color: black !important;
  width: 150px;

  &__title {
    font-size: 10px;
    color: #8c8d8d;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }

  &__sub-title {
    font-size: 10px;
  }

  .popover-body {
    font-size: 8px;
    color: $color-white;
    a {
      color: $color-white;
      text-decoration: underline;
    }
  }

  .arrow::after {
    border-top-color: black !important;
  }
}

.retail-panel {
  border-radius: 8px;
  box-shadow: 0 2px 12px 1px rgba(78, 144, 159, 0.11);
  background-color: $color-white;
  padding: 20px;

  &__header {
    height: 45px;
    border-radius: 10px 10px 0px 0px;
    border: solid 1px $color-dusty-gray-2;
    background-color: $dark--color-bg-grey;
    padding: 0 20px;
    font-size: 0.938rem;
    color: $color-white;
    line-height: 45px;
  }

  &__pharmacy_name {
    float: left;
    font-size: 0.75rem;
  }

  &__time-remaining {
    float: right;
    font-size: 0.75rem;
  }
}

.retail-container {
  &:has(div) {
    background-color: $color-dr-white;
  }
  padding: 25px;
  border-radius: 10px;

  &--extra-side-padding {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media only screen and (max-width: $breakpoint-sm) {
    padding: 10px 15px;

    &--extra-side-padding {
      padding: 10px 15px;
    }
  }
}

.retail-section {
  border: solid 1px rgba(72, 94, 113, 0.25);
  border-radius: 10px;
  padding: 20px 40px;
  position: relative;

  > .row {
    margin-bottom: 30px;
  }
}

.retail-title {
  @include font(1.5rem, 400, $color: $color-nostalgic-evening);
  margin-top: 27px;

  @media only screen and (max-width: $breakpoint-sm) {
    font-size: 1.125rem;
  }
}

.retail-sub-title {
  @include font(1.125rem, 300, $color: $color-nostalgic-evening);
  margin: 20px 0;

  @media only screen and (max-width: $breakpoint-sm) {
    font-size: 0.875rem;
  }
}

.retail-section-legend {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(56, 84, 113, 0.42);
  background-image: linear-gradient(245deg, #7e97aa 0%, #485e71 86%);
  color: #ffffff;

  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  font-size: 10px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: normal;

  position: absolute;
  top: 0;
  right: -1px;
  padding: 3px 6px;
  width: 275px;

  b {
    font-size: 13px;
    color: #ffffff;
  }
}

.retail-skip-button {
  cursor: pointer;
  display: inline-block;
  height: 90px;

  &__icon {
    height: 60px;
    width: auto;
    transition: all 0.5s ease-out;

    img {
      height: 100%;
      width: auto;
    }
  }

  &__text {
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 3px;
    text-transform: uppercase;
  }

  &:focus,
  &:hover {
    .retail-skip-button__icon {
      height: 70px;
    }
  }
}

.retail-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  color: #485e71;
  background-color: white;
  background-clip: border-box;
  border: 2px solid rgba(0, 0, 0, 0.175);
  border-radius: 10px;

  .card-body {
    color: #485e71;
    padding: 16px;
  }

  .card-title {
    font-size: 32px;
    font-weight: 500;
    text-align: center;
  }

  .card-subtitle {
    text-align: center;
  }
}
