$dark--color-primary: #fe7183;
$dark--color-success: #6cf7bb;
$dark--color-warning: #fed071;
$dark--color-danger: #af1f27;

$dark--color-bg: #151619;
$dark--color-bg-grey: #666e7b;
$dark--color-border: #626263;

$dark--color-font: #666e7b;
$dark--color-slate-gray: #7b8698;
$dark--color-mine-shaft: #343434;
$dark--color-emperor: #4f4f4f;
$dark--color-shark: #28292f;
