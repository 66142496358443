@import 'abstracts/variables';
@import 'abstracts/mixins';

.ngb-tp-input-container {
  .btn {
    color: $color-bright-pink;
    padding: 0;
    width: 100%;
  }
}

.ngb-tp-meridian {
  .btn {
    color: $color-bright-pink;
    border-color: $color-bright-pink;
    box-shadow: none;

    &:hover,
    &:active {
      color: $color-white;
      background-color: $color-bright-pink;
    }
  }
}
